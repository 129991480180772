// extracted by mini-css-extract-plugin
export var background = "styles-module--background--I81fQ";
export var bdiv = "styles-module--bdiv--CswuX";
export var buttons = "styles-module--buttons--lMnX0";
export var description = "styles-module--description--4f6gi";
export var form = "styles-module--form--OFV2X";
export var formControl = "styles-module--form-control--9SQ3f";
export var formGroup = "styles-module--form-group--pfxHY";
export var formGroup2 = "styles-module--form-group2--Z-UNc";
export var inputText = "styles-module--input-text--FF-Mt";
export var size = "styles-module--size--1RCwo";
export var space = "styles-module--space--7LCAL";
export var text = "styles-module--text--vweSM";
export var textTitles = "styles-module--text-titles--RCw2E";
export var title = "styles-module--title--Nj4Fj";