// extracted by mini-css-extract-plugin
export var HeroText = "styles-module--Hero-text--UzM2h";
export var btn = "styles-module--btn--DIqut";
export var content = "styles-module--content--8gsDe";
export var heroContainer = "styles-module--hero-Container--BCq4l";
export var img = "styles-module--img--n49Fp";
export var img2 = "styles-module--img2--ZultV";
export var imgContainer = "styles-module--imgContainer--tpNYN";
export var link = "styles-module--link--9msGC";
export var reveal = "styles-module--reveal--FNQby";
export var reveal2 = "styles-module--reveal2--3nUzO";
export var reveal3 = "styles-module--reveal3--DOsge";
export var reveal4 = "styles-module--reveal4--EQaGC";
export var textContainer = "styles-module--text-Container--p8sWT";