// extracted by mini-css-extract-plugin
export var background = "styles-module--background--yilZz";
export var clear = "styles-module--clear--Y3C+Y";
export var code = "styles-module--code--01o9-";
export var description = "styles-module--description--GCQyV";
export var link = "styles-module--link--oSSl7";
export var pageTitle = "styles-module--page-title--YIHGq";
export var project1 = "styles-module--project1--ohjZ1";
export var project2 = "styles-module--project2--z2Uqe";
export var projectImage = "styles-module--project-image--+WOYu";
export var projectImage2 = "styles-module--project-image2--dzBUd";
export var projectTitle = "styles-module--project-title--OL-5v";